<template>
  <b-modal
    id="product-tokens"
    centered
    size="md"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @hidden="resetData"
  >
    <template #modal-header>
      <h4 class="m-modal__title">Loyalty points</h4>
    </template>
    <p class="d-flex align-items-center info-box info">
      <i class="fas fa-info-circle icon mr-3"></i>
      Upon purchasing the product, the customer will receive loyalty points.
      Every product brings a different amount of loyalty points to the customer.
      The amount of tokens can be determined with entry field below.
    </p>
    <p class="d-flex flex-wrap mb-2">
      <span class="info-label">Name: </span><br />
      <span class="font-weight-bold text-primary-color ml-2">
        {{ product.name }}</span
      >
    </p>
    <p class="mb-2">
      <span class="info-label">SKU:</span>
      <span class="ml-2 font-weight-bold text-primary-color">{{
        product.sku
      }}</span>
    </p>
    <p>
      <span class="info-label">Current value:</span>
      <span class="ml-2 font-weight-bold text-primary-color">{{
        product.tokens
      }}</span>
    </p>
    <validation-observer
      ref="tokenForm"
      tag="div"
      class="d-flex flex-column mt-4"
    >
      <validation-provider
        tag="div"
        name="tokens"
        rules="required|min_value:0"
        class="d-flex flex-column mb-2"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required"
          >Add token value for product</span
        >
        <b-form-input
          v-model="tokenValue"
          type="number"
          size="sm"
          class="col-7"
          no-wheel
          number
          :disabled="requesting"
        ></b-form-input>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="comment"
        rules="required"
        class="d-flex flex-column"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Comment</span>
        <b-form-textarea
          v-model="comment"
          size="sm"
          class="col-12"
          :disabled="requesting"
        ></b-form-textarea>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
    </validation-observer>
    <template #modal-footer="{ close }">
      <b-button
        variant="secondary"
        class="col-2"
        :disabled="requesting"
        @click="close"
        >Close</b-button
      >
      <b-button
        variant="primary"
        class="col-3"
        :disabled="requesting"
        @click="setProductTokenValue"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ProductTokens",
  data() {
    return {
      tokenValue: null,
      comment: "",
      requesting: false,
    };
  },
  computed: {
    product() {
      return this.$store.state.product.product;
    },
  },
  methods: {
    setProductTokenValue() {
      this.$refs.tokenForm.validate().then(async (success) => {
        if (success) {
          this.requesting = true;
          try {
            const res = await this.$store.dispatch(
              "product/changeProductTokenValue",
              {
                newTokenValue: this.tokenValue,
                comment: this.comment,
              }
            );
            this.$store.commit(
              "productsTable/UPDATE_PRODUCT_DATA",
              res.data.data
            );
            this.$bvModal.hide("product-tokens");
            this.$bvToast.toast("Product tokens updated successfully", {
              title: "Product tokens",
              variant: "success",
            });
          } catch (e) {
            console.error(e);
            this.$bvToast.toast(
              "There was and error updating the product tokens",
              {
                title: "Product tokens",
                variant: "danger",
              }
            );
          } finally {
            this.requesting = false;
          }
        }
      });
    },
    resetData() {
      this.tokenValue = null;
      this.comment = "";
    },
  },
};
</script>

<style scoped></style>
