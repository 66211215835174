<template>
  <b-table
    :items="items"
    :fields="fields"
    :busy="fetchingProducts"
    :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
    borderless
    sticky-header="72vh"
    responsive="true"
    class="m-table__wrapper"
    table-class="m-table"
    thead-class="m-table__header"
    thead-tr-class="m-table__header-tr"
    tbody-class="m-table__body"
    :tbody-tr-class="rowClass"
  >
    <template #cell(thumbnail)="{ value }">
      <div
        class="d-flex justify-content-center align-items-center product-image__wrapper"
      >
        <img v-if="value && value !== '/'" :src="value" class="product-image" />
        <img
          v-else
          :src="require('@/assets/images/no-image.jpeg')"
          class="product-image"
        />
      </div>
    </template>
    <template #cell(name)="{ item, value }">
      <i
        v-if="!item.isOnBigCommerce"
        class="fas fa-exclamation-triangle text-yellow-color"
        v-b-tooltip="
          'Product is not added in BigCommerce. Please add it to BigCommerce instance in order to include it in Loyalty System.'
        "
      ></i>
      {{ value }}
    </template>
    <template #cell(price)="{ value }">
      <span>{{ value / 100 }}€</span>
    </template>
    <template #cell(tokens)="{ item, value }">
      <div class="d-flex justify-content-center align-items-center">
        <span class="mr-3">
          <img
            :src="require('@/assets/images/butterlfy-icon.png')"
            class="token-icon"
            v-b-tooltip.hover="'Number of butterflies'"
          />
          x{{ value }}
        </span>
        <b-button
          :disabled="!item.isOnBigCommerce"
          variant="action"
          v-b-tooltip.hover="'Edit number of butterflies'"
          @click="editProductTokens(item)"
          ><i class="fad fa-pencil-alt"></i
        ></b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "ProductsTable",
  data() {
    return {
      fields: [
        {
          key: "thumbnail",
          label: "",
        },
        {
          key: "name",
          sortable: true,
          tdClass: "name",
          thClass: "name",
        },
        {
          key: "sku",
          sortable: true,
          tdClass: "sku",
          thClass: "sku",
        },
        {
          key: "price",
          label: "Price(without discount)",
          sortable: true,
          tdClass: "price",
          thClass: "price",
        },
        {
          key: "stock",
          sortable: true,
          tdClass: "stock",
          thClass: "stock",
        },
        {
          key: "source",
          sortable: true,
          tdClass: "source",
          thClass: "source",
        },
        {
          key: "tokens",
          label: "Loyalty points",
          sortable: true,
          tdClass: "tokens",
          thClass: "tokens",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.productsTable.products;
    },
    fetchingProducts() {
      return this.$store.state.productsTable.requesting;
    },
  },
  async created() {
    await this.getItems();
  },
  watch: {
    searchQuery() {
      this.getItems();
    },
  },
  methods: {
    async getItems() {
      await this.$store.dispatch("productsTable/getProducts");
    },
    editProductTokens(product) {
      this.$store.commit("product/SET_PRODUCT_DATA", product);
      this.$bvModal.show("product-tokens");
    },
    rowClass(item, type) {
      let className = "";
      if (item && type === "row") {
        if (!item.isOnBigCommerce) className = "inactive";
      }
      return "m-table__body-tr " + className;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/products/_products-table.scss";
</style>
