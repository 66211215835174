<template>
  <div class="p-4">
    <products-search />
    <products-table />
    <products-pagination />
    <product-tokens />
  </div>
</template>

<script>
import ProductsTable from "../components/products/ProductsTable";
import ProductsSearch from "../components/products/ProductsSearch";
import ProductsPagination from "../components/products/ProductsPagination";
import ProductTokens from "../components/products/modals/ProductTokens";
export default {
  name: "MProducts",
  components: {
    ProductTokens,
    ProductsPagination,
    ProductsSearch,
    ProductsTable,
  },
};
</script>
