<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h6>
      Overview of all products on eComes in the selected Loyalty group and
      determination of the token value assigned to the products.
    </h6>
    <div class="d-flex justify-content-end align-items-center w-50">
      <b-input-group class="col-4">
        <b-input-group-append
          ><div class="m-input__icon left">
            <i class="far fa-search"></i></div
        ></b-input-group-append>
        <b-form-input
          id="search"
          v-model="searchQuery"
          type="text"
          size="sm"
          placeholder="Search products"
          class="border-left-0 pl-0 py-2"
          debounce="500"
        ></b-form-input>
      </b-input-group>
      <b-button
        variant="primary"
        :disabled="downloading"
        v-b-tooltip.hover="'Export products in csv'"
        @click="exportCsv"
        ><i class="fas fa-download"></i
      ></b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsSearch",
  data() {
    return {
      downloading: false,
    };
  },
  computed: {
    searchQuery: {
      get() {
        return this.$store.state.productsTable.searchQuery;
      },
      set(value) {
        this.$store.commit("productsTable/SET_CURRENT_PAGE", 1);
        this.$store.commit("productsTable/SET_SEARCH_QUERY", value);
        this.$store.dispatch("productsTable/getProducts");
      },
    },
  },
  methods: {
    async exportCsv() {
      try {
        this.downloading = true;
        const res = await this.$axios.get("/products/export", {
          responseType: "blob",
        });
        this.downloadFile(res.data, "products");
      } catch (e) {
        console.error(e);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>

<style scoped></style>
